import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPut } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';

interface Props {
  entity: 'users' | 'onboarding-templates';
  sourceRecord: any;
  defaultReportsToUser: any;
  onUpdate?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface IUsers {
  name: string;
  id: string;
  email: string;
  disabled: boolean;
}

const UpdateReportsToSelect: FC<Props> = (props: Props) => {
  const { entity, defaultReportsToUser, sourceRecord, alertMessage, onUpdate } = props;

  const [selectedUser, setSelectedUser] = useState<IUsers | undefined>();
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (defaultReportsToUser) {
      setSelectedUser({
        name: `${defaultReportsToUser.firstname} ${defaultReportsToUser.lastname}`,
        id: defaultReportsToUser?.id,
        email: defaultReportsToUser?.email,
        disabled: false,
      });
    } else {
      setSelectedUser(undefined);
    }
  }, [defaultReportsToUser, sourceRecord]);

  useEffect(() => {
    loadAllTerritories();
  }, []);

  const loadAllTerritories = async () => {
    setIsLoadingUsers(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/users?size=10000`);
      const users = res.data?.data || [];
      setAllUsers(users);
      setIsLoadingUsers(false);
    } catch (error) {
      setIsLoadingUsers(false);
    }
  };

  // Update user if different one is selected
  const handleItemSelect = (item: any) => {
    if (item.id !== defaultReportsToUser?.id) {
      updateEntityWithReportsToUser(item);
    }
  };

  const updateEntityWithReportsToUser = async (selectedUser: IUsers) => {
    setIsUpdating(true);

    let version = entity === 'users' ? 'v1.0' : 'v2.0';

    try {
      await httpPut(`IdentityModule/${version}/${entity}/${sourceRecord.id}`, {
        reportsTo: selectedUser.id === 'nobody' ? null : selectedUser.id,
      });

      if (selectedUser.id === 'nobody') {
        setSelectedUser(undefined);
      } else {
        setSelectedUser(selectedUser);
      }

      setIsUpdating(false);
      alertMessage({
        body: `User updated`,
        type: 'success',
      });
    } catch (error: any) {
      setIsUpdating(false);
      alertMessage({
        body: 'Could not update user. ' + error.message,
        type: 'error',
      });
    }
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const renderUser: ItemRenderer<IUsers> = (
    user,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else if (user.id === 'search-info') {
      return (
        <MenuItem
          disabled={true}
          key="load-more"
          roleStructure="menuitem"
          text="Use Filter to find more users..."
        />
      );
    } else
      return (
        <MenuItem
          active={user.id === selectedUser?.id}
          disabled={modifiers.disabled}
          key={user.id}
          label={user.email}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={user.name}
        />
      );
  };

  let USERS: IUsers[] = allUsers.map((f, index) => ({
    id: f.id,
    name: `${f.firstname} ${f.lastname}`,
    key: f.id,
    email: f.email,
    disabled: false,
  }));

  // Filter by search query
  if (searchQuery.length > 0) {
    USERS = USERS.filter((user) => {
      return user.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  USERS = USERS.slice(0, 100);

  // Append the Search information if there are more than 100 items
  if (USERS.length === 100) {
    USERS.push({
      id: 'search-info',
      name: 'search-info',
      disabled: true,
      email: '',
    });
  }

  // Append "Nobody" option to the top of the list when the user is not searching
  if (searchQuery.length === 0) {
    USERS.unshift({
      id: 'nobody',
      name: '(Nobody)',
      email: '',
      disabled: false,
    });
  }

  return (
    <Select<IUsers>
      items={USERS}
      disabled={isLoadingUsers || allUsers.length === 0}
      itemRenderer={renderUser}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        icon={selectedUser ? 'person' : null}
        alignText="left"
        disabled={isLoadingUsers || allUsers.length === 0 || isUpdating}
        loading={isLoadingUsers}
        text={selectedUser?.name || 'Select User'}
        rightIcon="caret-down"
        fill
      />
    </Select>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UpdateReportsToSelect);
